<template>
  <div class="roadmap">
    <b-container>
      <h2
        class="title title--fir revealOnScroll"
        data-animation="bounce"
        data-timeout="200"
      >
        Roadmap
      </h2>
      <div v-for="key in Object.keys(roadmap)" :key="key" class="list">
        <div class="year">{{ key }}</div>
        <ul>
          <li
            v-for="(chileKey, index) in Object.keys(roadmap[key])"
            :key="chileKey"
            class="child"
            :class="{
              'child-left': index % 2 !== 0,
              'child-right': index % 2 === 0,
            }"
          >
            <div class="child-tilte">{{ chileKey }}</div>
            <ul class="child-list">
              <li
                v-for="(li, index) in roadmap[key][chileKey]"
                :key="index"
                class="item"
              >
                {{ li }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MRoadMap',
  data () {
    return {
      roadmap: {
        2021: {
          q3: [
            'Metadoge Alpha test & release.',
            'Develop the NFT integrated marketplace and NFT smart.'
          ],
          q4: [
            'Optimize PVE economic model.',
            'NFT integrated marketplace goes live.',
            'Open community governance and liquidity mining.'
          ]
        },
        2022: {
          q1: [
            'Metadoge PVP version test & release.',
            'Establish game commune, and explore new modes of game cooperation.'
          ],
          q2: [
            'Launch and improve veMTD community governance platform.',
            'Progressively enhance the integrated value of MTD.'
          ],
          q3: [
            'Establish a unified and shared NFT consensus platform with multiple blockchain games.',
            'initiate cooperation program with third parties.'
          ],
          q4: [
            'Continuously optimize the game commune platform.',
            'Progressive and in—depth cooperation with more blockchain games.'
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.roadmap {
  padding-top: $space-block;
  padding-bottom: 6.56rem;
  background: #f79e2f url(../../assets/bg_roadmap.png);
  text-align: center;
  cursor: url('../../assets/dragon.svg'), auto;

  .title--fir {
    color: $m-black;
  }

  .list {
    padding-bottom: 2rem;
    background-repeat: repeat-y;
    background-image: radial-gradient($m-black 12%, transparent 13%);
    background-size: 1.25rem 1.25rem;
    background-position: 50% 0px;
    color: $m-black;

    &:nth-last-of-type(1) {
      padding-bottom: 3rem;
    }

    & ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .year {
      display: inline-block;
      padding: 0 1rem;
      margin-bottom: 2.625rem;
      background: #081016;
      border-radius: 18px;
      color: $primary;
      font-size: 1.5rem;
      user-select: none;
    }

    .child-left,
    .child-right {
      display: flex;
      align-items: center;
    }

    .child-left {
      width: 50%;
      flex-direction: row-reverse;
      transform: translateX(1rem);

      .child-tilte {
        margin-left: 1.25rem;
      }

      .child-list::before {
        right: -0.25rem;
        transform: translateY(-50%) rotate(135deg);
      }

      .child-list:hover {
        box-shadow:inset 15px 0px  10px -15px #000;
      }
    }

    .child-right {
      padding-left: calc(50% - 1rem);
      .child-tilte {
        margin-right: 1.25rem;
      }

      .child-list::before {
        left: -0.25rem;
        transform: translateY(-50%) rotate(-45deg);
      }

      .child-list:hover {
        box-shadow:inset -15px 0px  10px -15px #000;
      }
    }

    .child-tilte {
      width: 2rem;
      height: 2rem;
      text-align: center;
      line-height: 2rem;
      font-size: 1rem;
      border-radius: 1rem;
      background: $m-black;
      color: $primary;
      text-transform: uppercase;
      flex-shrink: 0;
      user-select: none;
    }

    .child-list {
      position: relative;
      padding: 1rem 1.75rem;
      background-color: #ffce74;
      border-radius: 0.625rem;
      color: $m-black;
      list-style: disc;
      cursor: auto;
      transition: box-shadow .15s;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        width: .5rem;
        height: .5rem;
        background: linear-gradient(to top, #ffce74, #ffce74) no-repeat, linear-gradient(to right, #ffce74, #ffce74) no-repeat, linear-gradient(135deg, #ffce74, #ffce74 6px, transparent 6px) no-repeat;
        background-size: .5rem 1px, 1px .5rem, .5rem .5rem;
      }
    }

    .item {
      text-align: left;
    }

    @media screen and (max-width: 768px) {
      .child-tilte {
        display: none;
      }

      .child-left {
        width: 100%;
        transform: translateX(0);
        flex-direction: row;
      }

      .child-right {
        padding-left: 0;
      }

      .child-list {
        flex: 1;

        &::before {
          display: none;
        }
      }

      .child {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
