<template>
  <div class="banner">
    <m-nav />
    <div class="bg-wrapper">
      <img
        class="dragon banner-img"
        :src="require('../../assets/banner-dragon.png')"
        alt="dragon"
      />
      <div class="bg"></div>
      <img
        class="hero banner-img"
        :src="require('../../assets/banner-hero.png')"
        alt="hero"
      />
      <div class="content">
        <img class="img" :src="require('@/assets/logo_big.png')" />
        <h2 class="mt-5 mb-4 pt-1 pb-1 banner-title">
          Welcome Home, Dogeroes!
        </h2>
        <p class="banner-title">Play & Earn 200 Limited Dogero NFTs</p>
        <b-container class="mt-5 pt-4">
          <b-row align-h="center">
            <b-col sm="12" lg="3" class="mb-3">
              <b-button class="button" variant="outline-primary"
                >Buy NFTs</b-button
              >
            </b-col>
            <b-col sm="12" lg="3" class="mb-3">
              <b-button class="button" variant="outline-primary"
                >Join the Airdrop</b-button
              >
            </b-col>
            <b-col sm="12" lg="3" class="mb-3">
              <b-button class="button" variant="outline-primary"
                >Read White Paper</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <slot></slot>
    <m-footer />
  </div>
</template>

<script>
import MNav from '@/components/MNav'
import MFooter from '@/components/MFooter'

export default {
  name: 'MBanner',
  components: {
    [MNav.name]: MNav,
    [MFooter.name]: MFooter
  }
}
</script>

<style lang="scss" scoped>
.banner {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;

  .bg-wrapper {
    position: relative;
    height: calc(100vh - 74px);
    overflow: hidden;
    cursor: url('../../assets/torch.png'), auto;

    .bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: url("../../assets/banner-bg.png");
      background-attachment: fixed;
      transform-style: preserve-3d;
    }

    .banner-img {
      width: 100%;
      height: auto;
    }

    .dragon {
      height: 100vh;
      position: absolute;
      bottom: 0;
      transform: translateZ(-1px) scale(1.5);
      z-index: 1;
    }

    .hero {
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 7rem;
    padding-bottom: 17rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    color: #fff;
    z-index: 2;

    .banner-title {
      font-family: BPdotsSquareBold;
      // cursor: auto;
    }

    @media screen and (min-width: 1920px) {

    }

    .button {
      width: 16.375rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 768px) {
  .banner {
    .content {
      padding-bottom: 10rem;
      padding-top: 4rem;

      .dragon {
        object-fit: cover;
      }
    }
  }
}
</style>
