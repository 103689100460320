<template>
  <b-container class="model">
    <h2
      class="title title--fir revealOnScroll"
      data-animation="bounce"
      data-timeout="200"
    >
      Economic model
    </h2>
    <b-row>
      <b-col lg="6" sm="12" class="item">
        <p class="left">
          The distribution of the token MTD which amounts to 300,000,000 is as
          follows :
        </p>
        <!-- <img class="img m-auto d-block" :src="require('@/assets/model_1.png')" /> -->
        <div
          ref="chart"
          class="chart"
        ></div>
      </b-col>
      <b-col lg="6" sm="12" class="item">
        <img
          :src="require('@/assets/model_2.png')"
          class="right-img img d-block"
        />
        <p class="p">
          MTD, the token for the metadoge game world, can be used for :
        </p>
        <h3 class="title">Governance</h3>
        <p class="p">
          MTD token holders will be allowed to stake tokens to participate in
          platform governance voting.
        </p>
        <h3 class="title">Token Staking</h3>
        <p class="p">
          Players will be able to stake MTD tokens to earn weekly rewards.
        </p>
        <h3 class="title">Payments</h3>
        <p class="p">
          Players can use MTD tokens for various in-game payments.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { TitleComponent, LegendComponent } from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'

// 注册必须的组件
echarts.use([
  TitleComponent,
  LegendComponent,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
])

export default {
  name: 'MModel',
  mounted () {
    this.drawChart()
  },
  methods: {
    drawChart () {
      const clientWidth = document.body.clientWidth
      const optionTitle = {
        text: 'Distribution\nPlan',
        textStyle: {
          color: '#fff',
          fontWeight: 'normal',
          fontSize: 15
        },
        textAlign: 'center',
        left: '49%'
      }
      let options = {}
      var myChart = echarts.init(this.$refs.chart)

      if (clientWidth <= 768) {
        options = {
          title: {
            ...optionTitle,
            top: '54%'
          },
          legend: {
            show: true,
            top: 0,
            left: 0,
            textStyle: {
              color: '#fff'
            }
          },
          series: [
            {
              type: 'pie',
              radius: ['35%', '78%'],
              center: ['50%', '58%'],
              data: [
                {
                  name: 'Selling',
                  value: 25
                },
                {
                  name: 'Community',
                  value: 10
                },
                {
                  name: 'Teams & Consultants',
                  value: 20
                },
                {
                  name: 'Play to earn',
                  value: 15
                },
                {
                  name: 'Staking',
                  value: 15
                },
                {
                  name: 'Ecology',
                  value: 15
                }
              ],
              label: {
                position: 'inside',
                alignTo: 'none',
                bleedMargin: 5,
                rich: {
                  percent: {
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#fff',
                    padding: [0, 0, 6, 0]
                  }
                },
                formatter: function (params) {
                  return `{percent|${params.percent}%}`
                }
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: '#0a1015'
              }
            }
          ]
        }
      } else {
        options = {
          title: {
            ...optionTitle,
            top: '46%'
          },
          series: [
            {
              type: 'pie',
              radius: ['35%', '80%'],
              center: ['50%', '50%'],
              data: [
                {
                  name: 'Selling',
                  value: 25
                },
                {
                  name: 'Community',
                  value: 10
                },
                {
                  name: 'Teams & Consultants',
                  value: 20
                },
                {
                  name: 'Play to earn',
                  value: 15
                },
                {
                  name: 'Staking',
                  value: 15
                },
                {
                  name: 'Ecology',
                  value: 15
                }
              ],
              label: {
                position: 'outside',
                alignTo: 'none',
                bleedMargin: 5,
                rich: {
                  percent: {
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: 'auto',
                    padding: [0, 0, 6, 0]
                  },
                  name: {
                    color: '#fff'
                  }
                },
                formatter: function (params) {
                  return `{percent|${params.percent}%}\n{name|${params.name}}`
                }
              },
              labelLayout: function (params) {
                const isLeft = params.labelRect.x < myChart.getWidth() / 2
                const points = params.labelLinePoints
                // Update the end point.
                points[2][0] = isLeft
                  ? params.labelRect.x
                  : params.labelRect.x + params.labelRect.width
                return {
                  labelLinePoints: points
                }
              },
              itemStyle: {
                borderWidth: 1,
                borderColor: '#0a1015'
              }
            }
          ]
        }
      }

      myChart.setOption({
        ...options,
        color: ['#fbe560', '#f7c258', '#f4ae54', '#f19850', '#ef834d', '#ed6749']
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.model {
  padding-top: $space-block;
  padding-bottom: $space-block;
  text-align: center;

  .item {
    text-align: left;

    .chart {
      max-width: 100%;
      width: 32.5rem;
      height: 22.5rem;
      margin-left: auto;
      margin-right: auto;
    }

    .left {
      margin-top: 5rem;
      margin-bottom: 5.625rem;
      color: #fff;
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    @media screen and (max-width: 768px) {
      .chart {
        margin-bottom: 1rem;
      }

      .left {
        margin-top: 0rem;
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: 320px) {
    }
  }

  .right-img {
    margin-bottom: 3.56rem;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
