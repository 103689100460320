<template>
  <div class="home">
    test
    <m-banner id="home" ref="home">
      <m-intro id="intro" />
      <m-ecology id="ecology" />
      <m-hero id="nft" />
      <m-model id="model" />
      <m-road-map id="roadmap" />
    </m-banner>
  </div>
</template>

<script>
import MBanner from './home/MBanner'
import MIntro from './home/MIntro'
import MEcology from './home/MEcology'
import MHero from './home/MHero'
import MModel from './home/MModel'
import MRoadMap from './home/MRoadMap'

export default {
  name: 'Home',
  data () {
    return {}
  },
  watch: {
    $route () {
      this.toTarget()
    }
  },
  mounted () {
    this.toTarget()
    this.$refs.home.$el.addEventListener('scroll', this.handleScroll)
  },
  components: {
    [MBanner.name]: MBanner,
    [MIntro.name]: MIntro,
    [MEcology.name]: MEcology,
    [MHero.name]: MHero,
    [MModel.name]: MModel,
    [MRoadMap.name]: MRoadMap
  },
  methods: {
    toTarget () {
      const { path, query } = this.$route
      const { anchor } = query

      if (path === '/' && anchor) {
        const elAnchor = document.getElementById(anchor)
        document.querySelector('#home').scrollTo({
          top: elAnchor.offsetTop,
          behavior: 'smooth' // 平滑滚动
        })
      }
    },
    handleScroll () {
      const scroll = this.$refs.home.$el.scrollTop
      const winHeightPadded = document.body.clientHeight

      document.querySelectorAll('.revealOnScroll:not(.animated)').forEach(item => {
        var offsetTop = item.offsetTop

        if (scroll + winHeightPadded > offsetTop) {
          if (item.dataset.timeout) {
            window.setTimeout(function () {
              item.classList.add('animate__animated')
              item.classList.add('animate__' + item.dataset.animation)
            }, parseInt(item.dataset.timeout, 10))
          } else {
            item.classList.add('animate__animated')
            item.classList.add('animate__' + item.dataset.animation)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
body {
  scroll-behavior: smooth;
}
.home {
  .btn-outline-primary {
    background-color: rgba(9, 27, 39, 0.75);
    padding-left: 3rem;
    padding-right: 3rem;

    &:hover {
      color: #fea;
      border-color: #fea;
    }
  }
}
</style>
