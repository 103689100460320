<template>
  <div class="intro">
    <b-container>
      <b-row align-v="center">
        <b-col lg="6" sm="12" class="mb-3 into__left">
          <h2 class="title--fir title revealOnScroll" data-animation="bounce" data-timeout="200">Game Intro</h2>
          <p class="p">
            Metadoge is a pixel-style turn-based RPG game. The ancient dragon
            awakens on the magic continent, and players need to gather dogeroes
            to defeat the evil dragon and make the world peaceful again.
          </p>
          <p class="p mt-3">
            Players get rewards by participating in the game and community
            governance and build an exclusive world belonging to the dogeroes
            through adventure, matchmaking, blind box and other gameplay.
          </p>
          <h3 class="title">Dogero Acquisition</h3>
          <p class="p">
            Dogero is the hero of the metadoge. You can acquire dogeroes by
            buying, renting, and opening blind boxes.
          </p>
          <h3 class="title">Adventure missions</h3>
          <p class="p">
            You need to equip your dogeroes and form teams for adventure
            challenges.
          </p>
          <h3 class="title">Open blind boxes</h3>
          <p class="p">
            You can consume materials to get the blind boxes and open the blind
            boxes to win the new dogeroes.
          </p>
          <h3 class="title">PVP</h3>
          <p class="p">
            You can match opposing players in the arena and use dogeroes to
            initiate battles.
          </p>
          <h3 class="title">Metaverse</h3>
          <p class="p">
            All players build a metaverse where the dogeroes territory, battles,
            and prosperity are included.
          </p>
        </b-col>
        <b-col lg="6" sm="12" class="img-wrap">
          <img
            class="img m-auto d-block"
            :src="require('@/assets/intro.png')"
          />
          <div class="play-wrap">
            <div class="play" @click="$bvModal.show('video')"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="video"
      size="xl"
      title="MetaDoge — My TOP NFT Game of HUGE Potential (Play To Earn)"
    >
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/D-FsgzQ8MeY"
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'MIntro'
}
</script>

<style lang="scss" scoped>
.intro {
  padding-top: 5.5rem;
  padding-bottom: 5rem;
  background: #091b27;

  &__left {
    cursor: auto;
  }

  .img-wrap {
    position: relative;

    .img {
      filter: brightness(0.7) grayscale(0.1) blur(0.5px);

      &:hover {
        cursor: url("../../assets/icon_play.svg"), auto;
      }
    }

    .play-wrap {
      position: absolute;
      width: 6rem;
      height: 6rem;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: url("../../assets/icon_play.svg"), auto;

      &:hover .play {
        width: 6rem;
        height: 6rem;
        border-radius: 3rem;
      }
    }

    .play {
      display: inline-block;
      width: 4.75rem;
      height: 4.75rem;
      border-radius: 2.375rem;
      background: rgba(13, 27, 38, 0.8);
      border: 4px solid #cbdce7;
      transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 6px);
        transform: translateY(-50%);
        display: inline-block;
        border-style: solid;
        border-width: 0.8125rem 1.125rem;
        border-color: transparent transparent transparent $primary;
      }
    }
  }
}
</style>

<style lang="scss">
.modal-title {
  color: #0a1015;
  mix-blend-mode: difference;
}

.modal-footer {
  display: none;
}
</style>
