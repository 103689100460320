<template>
  <b-container class="ecology">
    <h2 class="title title--fir revealOnScroll" data-animation="bounce" data-timeout="200">Game Ecology</h2>
    <b-row align-h="center">
      <b-col lg="9"
        >The essential element of the game ecology are dogeroes, and the token
        MTD is the only proof of value in the circulation of the game ecology.
        All players have their roles in the game and get corresponding income by
        finishing their missions, which constitutes a healthy functioning
        ecological space.</b-col
      >
    </b-row>
    <b-row align-h="center" class="content">
      <b-col lg="4" md="6" sm="12" class="item">
        <img class="item__img" :src="require('@/assets/ec1.png')" />
        <h3 class="title title--sec">NFT</h3>
        <p class="p">
          All dogeroes are NFTs which means every dogero is unique.
        </p>
      </b-col>
      <b-col lg="4" md="6" sm="12" class="item">
        <img class="item__img" :src="require('@/assets/ec2.png')" />
        <h3 class="title title--sec">Interesting gameplay</h3>
        <p class="p">
          Open a blind box and you will get a dogero by random, which means high
          risk and generous rewarding.
        </p>
      </b-col>
      <b-col lg="4" md="6" sm="12" class="item">
        <img class="item__img" :src="require('@/assets/ec3.png')" />
        <h3 class="title title--sec">Metaverse</h3>
        <p class="p">
          All players constitute an exclusive world belonging to the dogeroes
          together.
        </p>
      </b-col>
      <b-col lg="4" md="6" sm="12" class="item">
        <img class="item__img" :src="require('@/assets/ec4.png')" />
        <h3 class="title title--sec">Various Forms</h3>
        <p class="p">
          The game offers various ways for players to play including PVE and
          PVP.
        </p>
      </b-col>
      <b-col lg="4" sm="12" class="item">
        <img class="item__img" :src="require('@/assets/ec5.png')" />
        <h3 class="title title--sec">Play & Earn</h3>
        <p class="p">
          You can earn your MTD while playing the game, which means you can get
          fun and reward in the meanwhile.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'MEcology'
}
</script>

<style lang="scss" scoped>
.ecology {
  padding-top: $space-block;
  padding-bottom: 6rem;
  text-align: center;

  .content {
    margin-top: 4.5rem;

    .col-lg-4 {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .item {
      margin-bottom: 2rem;

      &__img {
        transition: transform 0.4s ease-in, filter 0.4s ease-out;
        filter: grayscale(0.9);

        @media screen and (max-width: 768px) {
          filter: grayscale(0);
        }
      }

      .title {
        filter: brightness(.6);
        // color: #fff;
        transition: filter 0.4s ease-out;
      }

      &:hover {
        cursor: url("../../assets/torch.png"), auto;

        .title {
          // color: $primary;
          filter: brightness(1);
        }

        .item__img {
          // transform: scale(1.1) translateY(-8px);
          filter: grayscale(0) drop-shadow(0px 0px 40px rgba(244, 175, 66, 0.7));

          @media screen and (max-width: 768px) {
            transform: none;
          }
        }
      }
    }
  }
}
</style>
