<template>
  <div class="heros">
      <h2 class="title title--fir revealOnScroll" data-animation="bounce" data-timeout="200">NFT</h2>
      <b-container>
        <p class="p">You can play with the purchased dogero NFT after the game is launched.</p>
      </b-container>
      <b-container class="content">
        <b-row gutter="10">
          <b-col lg="3" md="6" sm="12" v-for="(item, index) in heros" :key="index">
            <div class="item">
              <img :src="require(`@/assets/hero/${ item.img }.jpg`)" width="220" class="img" />
            <div class="intro">
              <img :src="require('@/assets/icon_coin.png')">
              <span class="name">{{ item.name }}</span>
              <div class="career ml-auto">{{ item.duty }}</div>
            </div>
            <b-button variant="outline-primary" class="buy">
              <img :src="require('@/assets/icon_shoppingcart.svg')" class="icon_shoppingcart">
              Buy on OpenSea
            </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-button variant="outline-primary" class="more">View More</b-button>
    </div>
</template>

<script>
export default {
  name: 'MHero',
  data () {
    return {
      heros: [
        {
          img: 'alex',
          name: 'Alex',
          duty: 'Warrior'
        },
        {
          img: 'wendy',
          name: 'Wendy',
          duty: 'Archer'
        },
        {
          img: 'wolfe',
          name: 'Wolfe',
          duty: 'Archer'
        },
        {
          img: 'jerome',
          name: 'Jerome',
          duty: 'Priest'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.heros {
  padding-top: $space-block;
  padding-bottom: $space-block;
  background: #091b27 url('../../assets/bg_hero.png') top 3rem center no-repeat;
  background-attachment: fixed;
  text-align: center;

   @media screen and (min-width: 360px) and (max-width: 768px) {
    background: #091b27 url('../../assets/bg_hero.png') top left;
  }

  .content {
    margin-top: 3.75rem;

    @media only screen and (min-width: 960px) and (max-width: 1140px) {
      .col-lg-3 {
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }

    .item {
      margin-bottom: 1.875rem;
      padding: 1.375rem;
      border: 4px solid #ffeeaa;
      border-radius: 10px;
      box-shadow: 2px 3px 0px 0px #b75301;

      .img {
        margin-bottom: .625rem;

        &:hover {
          cursor: url("../../assets/icon_aim.svg"), auto;
        }
      }

      .intro {
        display: flex;
        align-items: center;
        padding: .55rem .8rem;
        background: linear-gradient(90deg,#0d3e5d 0%, #0b2739 100%);
        border: 2px solid #072131;
        border-radius: 6px;

        .name {
          font-size: 1.5rem;
          margin-left: .3rem;
          margin-right: .3rem;
          font-weight: 700;
          color: #fff;
        }

        .career {
          font-weight: 700;
          color: #ffeeaa;
          align-self: flex-end;
        }
      }

      .buy {
        margin-top: .8rem;

        .icon_shoppingcart {
          width: 1.25rem;
          margin-right: .6rem;
          filter: invert(80%) sepia(64%) saturate(2936%) hue-rotate(344deg) brightness(101%) contrast(106%);
          transition: filter 0.15s ease-in-out;
        }

        &:hover {
          .icon_shoppingcart {
            filter: invert(100%) sepia(89%) saturate(954%) hue-rotate(313deg) brightness(101%) contrast(112%);
          }
        }
      }

      .btn-outline-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .more {
    margin-top: 3.75rem;
  }
}
</style>
